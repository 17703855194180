
          @import "~@/assets/css/_variables";
          @import "~@/assets/css/_sizes";
          @import "~@/assets/css/_sizesMobile";
        
































.fos-content {
  font-size: 2vh;
  @media screen and (min-width: 768px) {
    font-size: 1.2vh;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.4vh;
  }
  .h2 {
    font-size: 5vh;
    @media screen and (min-width: 1024px) {
      font-size: 5vh;
      margin-bottom: 1vh;
    }
  }
}
